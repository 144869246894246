import * as React from 'react';
import { getSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Button, InputGroup } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

// Placeholder Login View

const schema = yup.object().shape({
  username: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const PageLogin = (props: { csrfToken: string }) => {
  const { csrfToken } = props;
  const router = useRouter();
  const { handleSubmit, register } = useForm();

  const onSubmit = async (data) => {
    const response = await signIn('credentials', {
      redirect: false,
      username: data.username,
      password: data.password,
    });

    router.push('/dashboard');
  };

  React.useEffect(() => {
    (async () => {
      const session = await getSession();

      if (session) {
        return router.push('/dashboard');
      }

      return null;
    })();
  }, [router]);

  const usernameInput = register('username');
  const passwordInput = register('password');

  return (
    <div
      className={'flex flex-col space-y-4 items-center justify-center h-screen'}
    >
      <h1 className="font-bold">Vintro Admin</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup
          type="text"
          placeholder="Email"
          className="mb-3"
          name={usernameInput.name}
          onChange={usernameInput.onChange}
          onBlur={usernameInput.onBlur}
          inputRef={usernameInput.ref}
        ></InputGroup>
        <InputGroup
          type="password"
          placeholder="Password"
          className="mb-3"
          name={passwordInput.name}
          onChange={passwordInput.onChange}
          onBlur={passwordInput.onBlur}
          inputRef={passwordInput.ref}
        ></InputGroup>
        <Button type="submit" className="w-full">
          Login
        </Button>
      </form>
    </div>
  );
};

export default PageLogin;
